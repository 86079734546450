<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title class="ma-2">Page</v-card-title>
        <v-form class="mx-5" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12">
              <div>
                <v-text-field
                  outlined
                  ref="title"
                  type="text"
                  label="Title"
                  v-model="page.title"
                  :rules="[() => rule(page, 'title')]"
                />
              </div>
              <div>
                <a
                  class="d-flex justify-end"
                  href="https://pictogrammers.github.io/@mdi/font/5.4.55/"
                  target="_blank"
                >
                  check available icons here.
                </a>
                <v-text-field
                  outlined
                  ref="icon"
                  type="text"
                  label="Icon"
                  placeholder="e.g: mdi-account"
                  v-model="page.icon"
                  :rules="[() => rule(page, 'icon')]"
                />
              </div>
              <div class="my-5">
                <v-select
                  label="User type access"
                  v-model="userTypeAccess"
                  :items="getNonAdminUserTypes"
                  chips
                  multiple
                  outlined
                />
              </div>
              <div>
                <v-switch
                  outlined
                  ref="custom"
                  label="Custom"
                  v-model="page.custom"
                />
              </div>
              <div v-if="page.custom">
                <v-text-field
                  outlined
                  ref="to"
                  type="text"
                  label="Path"
                  v-model="page.to"
                  :rules="[() => rule(page, 'to')]"
                />
              </div>
              <template v-else>
                <div>
                  <v-text-field
                    outlined
                    type="text"
                    label="Primary Key"
                    hint="The table's primary key"
                    v-model="page.primary_key"
                    :rules="[() => rule(page, 'primary_key')]"
                  />
                </div>
                <div>
                  <v-text-field
                    outlined
                    ref="model"
                    type="text"
                    label="Model"
                    hint="Example: PageField for page_fields table"
                    v-model="page.model"
                    :rules="[() => rule(page, 'model')]"
                  />
                </div>
                <div class="my-5">
                  <v-select
                    label="Actions"
                    v-model="page.actions"
                    :items="permissions"
                    :rules="[() => hasPermissions]"
                    chips
                    multiple
                    outlined
                  />
                </div>
                <div class="mb-5">
                  <label>Fields</label>
                  <v-btn
                    icon
                    color="primary"
                    class="float-right"
                    @click="addField()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <template v-for="(pageField, i) in fields">
                  <div v-bind:key="i" class="mt-4">
                    <div class="d-flex justify-space-between">
                      <div class="d-flex">
                        <v-checkbox
                          outlined
                          class="mr-5"
                          label="Required"
                          v-model="fields[i].required"
                        />
                        <v-checkbox
                          outlined
                          class="mx-5"
                          label="Show on list"
                          v-model="fields[i].show"
                        />
                        <v-checkbox
                          outlined
                          class="mx-5"
                          label="Foreign key"
                          v-model="fields[i].is_foreign"
                          @change="onIsForeignChanged($event, i)"
                        />
                        <v-checkbox
                          outlined
                          class="mx-5"
                          label="Filterable"
                          v-if="isFilterable(fields[i])"
                          v-model="fields[i].filterable"
                        />
                      </div>
                      <v-btn
                        icon
                        v-if="i !== 0"
                        color="primary"
                        class="mt-0 mb-1 align-self-center"
                        @click="removeField(i)"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                    <v-select
                      outlined
                      v-if="fields[i].is_foreign"
                      label="Relation type"
                      v-model="fields[i].relation_type"
                      :ref="'relation_type' + i"
                      :items="foreignTypes"
                      @change="onRelationTypeChanged($event, i)"
                      :rules="[() => rule(fields[i], 'relation_type')]"
                    ></v-select>
                    <v-text-field
                      outlined
                      v-if="isNotToManyRelation(fields[i].relation_type)"
                      :ref="'name' + i"
                      type="text"
                      label="Name"
                      placeholder="column name"
                      v-model="fields[i].name"
                      :rules="[() => rule(fields[i], 'name')]"
                    />
                    <v-text-field
                      outlined
                      :ref="'label' + i"
                      type="text"
                      label="Label"
                      placeholder="Display name"
                      v-model="fields[i].label"
                      :rules="[() => rule(fields[i], 'label')]"
                    />
                    <v-select
                      outlined
                      label="Type"
                      v-if="!fields[i].is_foreign"
                      v-model="fields[i].type"
                      :ref="'type' + i"
                      :items="fieldTypes"
                      :rules="[() => rule(fields[i], 'type')]"
                    ></v-select>
                    <template v-if="fields[i].is_foreign">
                      <v-text-field
                        outlined
                        :ref="'foreign_table' + i"
                        type="text"
                        label="Foreign table"
                        v-model="fields[i].foreign_table"
                        :rules="[() => rule(fields[i], 'foreign_table')]"
                      />
                      <v-text-field
                        outlined
                        :ref="'foreign_table_column' + i"
                        type="text"
                        hint="The table column to display"
                        label="Foreign table column"
                        v-model="fields[i].foreign_table_column"
                        :rules="[() => rule(fields[i], 'foreign_table_column')]"
                      />
                      <v-switch
                        v-if="
                          fields[i].relation_type ===
                            relationshipTypes.ONE_TO_MANY_IMAGES
                        "
                        v-model="fields[i].soft_delete_enabled"
                        @change="fields[i].soft_delete_flag = null"
                        label="Enable soft delete"
                        outlined
                      />
                      <v-text-field
                        v-if="fields[i].soft_delete_enabled"
                        outlined
                        :ref="'soft_delete_flag' + i"
                        type="text"
                        hint="The boolean table column representing soft delete"
                        label="Soft delete flag column"
                        v-model="fields[i].soft_delete_flag"
                        :rules="[() => rule(fields[i], 'soft_delete_flag')]"
                      />
                      <v-text-field
                        outlined
                        v-if="
                          fields[i].relation_type ===
                            relationshipTypes.MANY_TO_MANY
                        "
                        :ref="'pivot_table' + i"
                        type="text"
                        label="Pivot table"
                        v-model="fields[i].pivot_table"
                        :rules="[() => rule(fields[i], 'pivot_table')]"
                      />
                      <v-text-field
                        outlined
                        v-if="!isNotToManyRelation(fields[i].relation_type)"
                        :ref="'foreign_key' + i"
                        type="text"
                        label="Foreign key"
                        v-model="fields[i].foreign_key"
                      />
                      <v-text-field
                        outlined
                        v-if="
                          fields[i].relation_type ===
                            relationshipTypes.MANY_TO_MANY
                        "
                        :ref="'related_key' + i"
                        type="text"
                        label="Related key"
                        v-model="fields[i].related_key"
                      />
                    </template>
                    <hr />
                  </div>
                </template>
              </template>
              <v-btn
                color="primary"
                class="mt-4 float-right"
                :loading="getPageLoading"
                :disabled="getPageLoading"
                @click="submit"
              >
                {{ isCreate ? "Create Page" : "Save changes" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </base-card>
      <v-dialog v-model="dialog" max-width="290">
        <v-card v-if="page">
          <v-card-title class="text-h6">
            Update {{ page.title }} Page
          </v-card-title>
          <v-card-text>
            Looks like you changed the model name.
            <v-checkbox
              outlined
              :label="`Delete existing ${currentModel} model`"
              v-if="!page.custom"
              v-model="shouldRemoveModel"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="danger" text :loading="getPageLoading" @click="save">
              update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { FIELD_TYPES, RELATION_TYPES } from "@/shared/constants";
import { labelsToIds } from "@/shared/utils";

export default {
  metaInfo: {
    title: "Page"
  },
  data() {
    return {
      isCreate: _.get(this.$route, "meta.kind") === "create",
      id: _.get(this.$route, "params.id"),
      item: null,
      hasError: false,
      fieldTypes: _.values(FIELD_TYPES),
      relationshipTypes: RELATION_TYPES,
      foreignTypes: _.values(RELATION_TYPES),
      dialog: false,
      currentModel: null,
      shouldRemoveModel: false,
      permissions: ["create", "edit", "delete"],
      userTypeAccess: [],
      page: {
        title: null,
        icon: null,
        order: null,
        custom: false,
        to: null,
        actions: [],
        model: null,
        primary_key: "id"
      },
      fields: []
    };
  },
  mounted: function() {
    if (this.isCreate) {
      return this.addField();
    }

    const page = this.$store.getters.getPage({ id: +this.id });
    this.currentModel = _.get(page, "model");
    this.page = _.omit(page, ["fields", "user_types"]);
    this.fields = _.get(page, "fields");
    this.userTypeAccess = _.map(_.get(page, "user_types", []), type =>
      _.get(type, "name")
    );
  },
  methods: {
    ...mapActions(["createPage", "updatePage"]),
    onRelationTypeChanged(type, index) {
      switch (type) {
        case RELATION_TYPES.ONE_TO_MANY:
          this.$set(this.fields, `${index}.name`, null);
          this.$set(this.fields, `${index}.pivot_table`, null);
          break;
        case RELATION_TYPES.MANY_TO_MANY:
          this.$set(this.fields, `${index}.name`, null);
          break;
        default:
          this.$set(this.fields, `${index}.pivot_table`, null);
          break;
      }
    },
    isNotToManyRelation(type) {
      return (
        type !== RELATION_TYPES.MANY_TO_MANY &&
        type !== RELATION_TYPES.ONE_TO_MANY &&
        type !== RELATION_TYPES.ONE_TO_MANY_IMAGES
      );
    },
    submit() {
      if (this.isCreate || this.currentModel === _.get(this.page, "model")) {
        return this.save();
      }
      this.dialog = true;
    },
    rule(obj, key) {
      return !_.isEmpty(_.get(obj, key)) || "This field is required";
    },
    onIsForeignChanged(value, index) {
      if (!value) {
        _.set(this.fields, `${index}.foreign_table`, null);
        _.set(this.fields, `${index}.foreign_table_column`, null);
      }
    },
    removeField(index) {
      if (index) {
        (this.fields || []).splice(index, 1);
      }
    },
    addField() {
      this.fields = [
        ...(this.fields || []),
        {
          name: null,
          label: null,
          type: null,
          required: false,
          show: false,
          is_foreign: false,
          soft_delete_enabled: false,
          soft_delete_flag: null,
          foreign_table: null,
          foreign_table_column: null,
          pivot_table: null,
          foreign_key: null,
          related_key: null,
          relation_type: null,
          filterable: false
        }
      ];
    },
    getPayload() {
      if (!_.get(this.page, "custom")) {
        return {
          ..._.omit(this.page, ["to"]),
          shouldRemoveModel: this.shouldRemoveModel,
          userTypeAccess: labelsToIds(
            this.getUserTypes,
            this.userTypeAccess,
            "name"
          ),
          fields: _.chain(this.fields)
            .map(field => {
              const data = _.omit(field, ["is_foreign", "soft_delete_enabled"]);
              switch (field.relation_type) {
                case RELATION_TYPES.ONE_TO_ONE:
                  data.type = FIELD_TYPES.SELECT;
                  break;
                case RELATION_TYPES.ONE_TO_MANY:
                  data.type = null;
                  break;
                case RELATION_TYPES.ONE_TO_MANY_IMAGES:
                  data.type = "gallery";
                  break;
                case RELATION_TYPES.MANY_TO_MANY:
                case RELATION_TYPES.MANY_TO_MANY_COMMA:
                  data.type = "multi-select";
                  break;
              }
              return data;
            })
            .value()
        };
      }
      return {
        ..._.omit(this.page, ["model", "actions"]),
        userTypeAccess: labelsToIds(
          this.getUserTypes,
          this.userTypeAccess,
          "name"
        )
      };
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }
      this.$store.dispatch("setPageLoading", true);
      try {
        const payload = this.getPayload();
        const { data: to } = this.isCreate
          ? await this.createPage(payload)
          : await this.updatePage({ id: this.id, payload });
        this.$store.dispatch(
          "showSnackbar",
          `${_.startCase(payload.title)} page saved.`
        );
        this.$router.push(
          _.get(payload, "custom") ? `/app/page` : `/app/models/${to}`
        );
      } catch (error) {
        console.error("DEBUG: error", error);
        this.$store.dispatch("showSnackbar", "Oops, something went wrong!");
        this.$store.dispatch("setPageError", error);
      }

      this.$store.dispatch("setPageLoading", false);
    },
    isFilterable(field) {
      return (
        _.get(field, "is_foreign") &&
        _.get(field, "relation_type") !== RELATION_TYPES.ONE_TO_MANY_IMAGES
      );
    }
  },
  computed: {
    ...mapGetters([
      "models",
      "getUserTypes",
      "getNonAdminUserTypes",
      "getPageLoading"
    ]),
    pageModel() {
      return _.kebabCase(_.get(this.page, "model"));
    },
    hasPermissions() {
      return !_.isEmpty(this.page.actions) || "This field is required";
    }
  }
};
</script>
<style lang="scss" scoped></style>
